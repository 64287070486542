@import '../../../../scss/theme-bootstrap';

.product-actions {
  text-align: center;
  padding: 6px 0 0 10px;
  &__action {
    @include transition(color, 200ms);
    margin-right: 20px;
    margin-bottom: 10px;
    display: inline-block;
    cursor: pointer;
    &:hover {
      color: $color-green;
    }
    &:last-child {
      margin-right: 0;
    }
    .icon {
      font-size: 18px;
    }
    .icon-text {
      @include a1;
    }
  }
}
